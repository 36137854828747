import React from 'react';
import { graphql,Link } from 'gatsby';
import styled from 'styled-components';
import TwoColLayout from '../components/layout/TwoColLayout';
import {Helmet} from "react-helmet";
import '@suziwen/gitalk/dist/gitalk.css'
import IssueCardTemplate from '../components/templates/IssueCardTemplate';
import {IssueListQuery} from "../../graphql-types";

type Props = {
  data: IssueListQuery
}

export default function IssueListPage(props:Props) {
  const { title:siteTitle,description:siteDesc,siteUrl } = props.data.site.siteMetadata;
  const MainContent = styled.div`
    .pankuzu{ font-size:12px; }
    .pankuzu a{ text-decoration: none; color:#333; }
    .page-header{ background-color: #333; padding: 30px; }
    .page-header .gatsby-image-wrapper{ border-radius: 5px; }
    .page-header h1{ font-size: 26px; font-weight: bold; color: white; }
    /* .post-meta a{ text-decoration: none; color:#ddd; } */
    /* .post-meta svg{ transform: translate(1px,-2px); } */
    .gt-container{ margin-bottom: 40px; }
    /* .date,.tag{ font-size:13px; color:#ddd; } */
    /* .markdown-body pre{ border:1px solid black; } */
  `;
  return (
    <>
      <Helmet>
        <html lang="ja" />
        <title>問題解決一覧 | TERAKOYA（{siteDesc}）</title>
        <meta name="description" content={siteDesc} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`問題解決一覧 | ${siteTitle}|${siteDesc}`} />
        <meta property="og:description" content={siteDesc} />
        <meta property="og:image" content={`${siteUrl}/logo.png`} />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:width" content="1200" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={`${siteUrl}/logo.png`} />
        <meta name="twitter:title" content={`問題解決一覧 | ${siteTitle}|${siteDesc}`} />
        <meta name="twitter:description" content={siteDesc} />
      </Helmet>
      <TwoColLayout
        data={props.data}
        main={
          <MainContent>
            <Helmet>
              <html lang="ja" />
              <title>問題解決一覧 | TERAKOYA</title>
              <meta name="description" content="ITテックブログです" />
            </Helmet>
            <h1>問題解決一覧</h1>
            <IssueCardTemplate data={props.data.allMdx} />
          </MainContent>
        }
        side={
          <div>
          </div>
        }
      />
    </>
  );
}

export const query = graphql`
  query IssueList {
    site {
      siteMetadata {
        title
        siteUrl
        description
        headerLinks {
          label
          url
        }
      }
    }
    allMdx(
      sort: { order: DESC, fields: frontmatter___date }, 
      filter: { 
        frontmatter: { 
          publish: { eq: true } 
          posttype: { eq: "issue" } 
        } 
      }
    ) {
      nodes {
        id
        tableOfContents
        frontmatter {
          slug
          date
          title
          posttype
          icon {
            childImageSharp {
              gatsbyImageData
            }
          }
          open
          tags
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`;
