import React, { ReactNode } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../views/Header';
import Footer from '../views/Footer';
import PageTopButton from '../parts/PageTopButton';
import styled from 'styled-components';

type Props = {
  data: any
  main?: ReactNode
  side?: ReactNode
}
const Wrapper = styled.div`
  @media screen and (max-width: 480px) {
    overflow: hidden;
  }
`;
const TwoColLayout = (props: Props) => {
  const { data, main, side } = props;
  return (
    <Wrapper>
      <Container fluid className="px-0 main">
        <Row className="justify-content-center">
          <Col>
            <Header data={data} />
          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <Row>
          <Col lg={8} md={8} sm={12} xs={12}>
            {main}
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            {side}
          </Col>
        </Row>
      </Container>
      <Container fluid className="px-0">
        <Row>
          <Col className="footer-col">
            <Footer />
          </Col>
        </Row>
        <PageTopButton />
      </Container>
    </Wrapper>
  );
}

export default TwoColLayout;